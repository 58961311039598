import {
  IVisitorOnboardingTranslations,
  IVisitorExhibitorTranslations,
  IVisitorSettingsTranslations,
  IVisitorSidebar,
  IVisitorsDashboardTranslations,
  IVisitorNetworkingTranslations,
  IVisitorExhibitorSessions,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
  IVisitorStore,
  IVisitorChatMessages,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Perfil',
  networking: 'Networking',
  networking_description: 'Falar com outros visitantes',
  sessions: 'Agenda',
  sessions_description: 'Número de sessões na agenda do evento:',
  sessions_btn: 'Ver agenda do evento',
  name: 'Nome de visitante:',
  edit: 'Alterar',
  exhibitors: 'Expositores',
  nrOfInteractions: 'Número de contactos partilhados:',
  seeAll: 'Ver expositores',
  download: 'Descarregar',
  join_networking: 'Entrar no espaço de networking',
  event_finished: 'Este evento já terminou',
  event_not_start: 'Este evento ainda não começou',
  featured_products: 'Ofertas em destaque',
  featured_sessions: 'Sessões em destaque',
  featured_exhibitors: 'Expositores em destaque',
  upcoming_exhibitors: 'Próximas sessões',
};

const sidebar: IVisitorSidebar = {
  title: 'Visitante',
  home: 'Home',
  exhibitors: 'Expositores',
  settings: 'Perfil',
  sessions: 'Agenda',
  exhibitor_sessions: 'Agenda pessoal',
  event_sessions: 'Agenda do evento',
  myCalendar: 'Agenda pessoal',
  eventCalendar: 'Agenda do evento',
};

const store: IVisitorStore = {
  title: 'Loja',
  subtitle: 'Acrescentar sessões e/ou itens adicionais',
  add_to_cart: 'Adicionar ao carrinho',
  other_addons: 'Outros add-ons',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Formulário de visitante',
  basicInfo: 'Informação básica',
  save: 'Guardar',
  additionalInfo: 'Informação de registo',
  formError: 'Sem informação para mostrar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Expositores',
  subtitle: 'Ver expositores e consultar produtos',
  download: 'Descarregar',
  noExhibitors: 'Sem expositores para mostrar.',
  exhibitor_information: 'Informação de expositor',
  contact_success: 'Partilha de contactos efetuada com sucesso!',
  all_exhibitors: 'Todos os expositores',
  interacted_exhibitors: 'Expositores com que interagi',
  not_interacted_exhibitors: 'Expositores com que não interagi',
  filter_exhibitors: 'Filtrar expositores',
  contact_request: 'Partilhar contactos',
  company_info: 'Informação do expositor',
  job_offers: 'Ofertas',
  apply_to_job_offer: 'Registar interesse',
  job_offer_info: 'Informação do produto',
  cover_letter: 'Motivo do interesse',
  cover_letter_text: 'Deixe uma mensagem ao expositor',
  your_info_will_be_shared: 'A sua informação de registo será partilhada com o expositor',
  submit_my_application: 'Submeter',
  successfully_submited: 'Efetuado com sucesso',
  already_applied: 'Já efetuou este registo de interesse',
  schedule_interview: 'Reserve o seu lugar',
  select_time_slot: 'Selecione um horário para participar',
  all_visitors: 'Todos os visitantes',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Dados de visitante',
    createVisitor: 'Registar como visitante',
    select_type: 'Selecione o tipo de registo',
    modal: {
      title: 'Aviso',
      subtitle: 'Se quiser aceder ao evento após o registo terá que iniciar sessão primeiro.',
      skip: 'Saltar',
      createAccount: 'Criar conta',
      login: 'Iniciar sessão',
    },
    already_registered: 'Já está registado neste evento!',
    already_registered_pending: 'Já existe um registo seu pendente neste evento!',
    already_registered_awaiting_payment:
      'Já existe um registo seu neste evento a aguardar pagamento!',
    pre_registered_pending: 'Já tem um pré-registo para este evento!',
    send_payment_details:
      'Clique aqui para receber um email com detalhes para completar o pagamento.',
    send_login_link: 'Clique aqui para receber um email com link de acesso ao evento.',
    send_rsvp_link: 'Clique aqui para receber um email com link para ativar o registo.',
    no_payment_info: 'Não foi possível encontrar a informação relativa a este pagamento.',
    no_pre_registration: 'Não foi possível encontrar a informação relativa a este pré-registo.',
    payment_info_sent:
      'Verifique a sua caixa de entrada, enviámos informação para completar o pagamento!',
    rsvp_link_sent: 'Verifique a sua caixa de entrada, enviámos informação para ativar o registo!',
    pre_registered_email_message:
      'O endereço de email não pode ser alterado até se concluir a ativação do registo.',
  },
  managedFields: {
    title: 'Informação de registo',
  },
  tickets: {
    title: 'Bilhetes',
    subtitle: 'Selecione o bilhete que deseja adquirir',
    subtitle_store: 'Comprar bilhetes',
    message_head: 'Se precisar de comprar muitos bilhetes e registar-se mais tarde, por favor',
    to_buy_tickets: 'para comprar bilhetes;',
    copy_ticket_code: 'Copie o código que lhe enviaremos para o email e use-o para se registar.',
    ticket_code_invalid: 'O código do bilhete é inválido ou já foi usado',
    ticket_code_label: 'Código de registo',
    promo_code_label: 'Código promocional',
    ticket_code_placeholder: 'Insira o seu código',
    promo_code_placeholder: 'Insira o seu código promocional',
    use_ticket_code: 'Use um código de bilhete',
    code_message:
      'Caso tenha um código de desconto, use este campo para o ativar',
    promo_code_message: 'Use este campo para ativar códigos promocionais',
    promo_code_invalid: 'O código promocional é inválido ou já foi usado',
    non_applicable_promo_code:
      'O seu código promocional não se aplica aos bilhetes selecionados. Por favor, escolha outro.',
    promo_code_success:
      'Código promocional ativado com sucesso! <br> Por favor, selecione um bilhete.',
  },
  addons: {
    subtitle: 'Adquirir itens adicionais',
  },
  review: {
    title: 'Rever informação',
    name: 'Nome',
    proceed_to_payment: 'Proceder ao pagamento',
    register_someone_else: 'Registar outra pessoa',
  },
  checkout: {
    vat_label: 'Número de contribuinte',
    country_label: 'País',
    country_default: 'Seleccione o seu país',
    city_label: 'Cidade',
    address_label: 'Morada',
    zip_code: 'Código postal',
    payment_method: 'Método de pagamento',
    payment_method_card_label: 'Cartão de crédito ou de débito',
    payment_processed: 'Pagamento processado',
    check_your_phone: 'Conclua o pagamento na app MBWAY',
    billing_information: 'Informação de faturação',
    payment_failed: 'Pagamento falhado. Por favor, tente novamente',
    payment_declined: 'O número MB WAY utilizado não tem serviço.',
    payment_details_sent: 'Enviámos os detalhes do pagamento para o seu e-mail.',
    mbref_payment_message: 'Enviaremos uma referência MB para o seu correio electrónico',
    credit_card_label: 'Cartão de crédito ou débito',
    bank_transfer_label: 'Transferência bancária',
    bank_transfer_info: `
    <p>Pagamento por transferência bancária</p>
    <ul>
      <li>
      Após enviar este formulário, verifique o seu e-mail para informações de pagamento;
      </li>
      <li>Transfira o pagamento para os dados da conta bancária mencionada no e-mail;</li>
      <li>
       Envie o comprovativo de pagamento para o endereço de e-mail e mencione o ID do pagamento.
      </li>
    </ul>
    `,
    postal_code_error: 'Código postal com formato errado.',
  },
  completed: {
    title: 'Obrigado pela sua inscrição!',
    title_store: 'Obrigado pela sua compra!',
    subtitle: 'Por favor verifique a sua caixa de entrada, enviamos-lhe um e-mail de confirmação.',
    subtitle_store:
      'Por favor verifique a sua caixa de entrada, enviamos-lhe um e-mail com os bilhetes.',
    manage: 'Gerir',
  },
  back: 'Voltar',
  next: 'Próximo',
  submit: 'Submeter',
  not_allowed_title: 'A sua conta não foi aprovada',
  not_allowed_subtitle: 'Por favor aguarde pela aprovação do gestor de evento',
  registration_slow: 'O registo está a demorar mais que o habitual. Por favor, aguarde.',
  order_summary: 'Resumo da encomenda',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Fale com outros visitantes',
  chat: 'Chat',
  profile: 'Perfil',
  signup_title: 'Pretende falar com outros visitantes?',
  signup_message:
    'Está prestes a entrar na área de networking. A sua informação ficará disponível para outros visitantes.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Consulte aqui as suas inscrições nas sessões do evento',
  anonymous_subtitle: 'Consulte aqui e registe-se nas sessões do evento',
  event_sessions: 'Sessões do evento',
  exhibitor_sessions: 'Sessões de expositores',
  all_sessions: 'Todas as sessões',
  join_in_15: 'Esta sessão não está a decorrer',
  need_help: 'Precisa de ajuda?',
  refresh: 'Refresh',
  troubleshoot: 'Resolução de problemas',
  help_title: 'Tem problemas de som ou video?',
  help_content: `
    <p>
      Se tiver problemas com chat ou áudio, certifique-se de que não está a usar um proxy restrito
      ligação ou ter uma VPN activada neste momento. <br />
    </p>
    <p>
    Verifique
      <a
        href="https://www.easypcmod.com/how-to-fix-jitsi-meet-no-audio-problem-11976"
        target="_blank"
        style="text-decoration: underline"
        >este guia</a
      > para ajudar a resolver qualquer problema não resolvido através da actualização das definições da sua rede.
    </p>
    <p>
     As seguintes portas de rede devem estar abertas na sua firewall para permitir o tráfego do servidor:
    </p>
    <ul>
     <li>80 TCP – para a verificação/renovação de certificados SSL com Let’s Encrypt.</li>
     <li>443 TCP – para acesso geral ao software de videochamada;</li>
     <li>10000 UDP - para comunicações gerais de vídeo/áudio em rede.</li>
     <li>TCP/4443</li>
    </ul>
  `,
  select_stage: 'Selecione um palco',
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sessões em que está inscrito/a',
};

const profile: IVisitorProfile = {
  visitor_information: 'Informação do visitante',
};

const products: IVisitorProducts = {
  title: 'Ofertas',
  subtitle: 'Procurar e interagir com ofertas de expositor',
  products_same_brand: 'Ofertas do mesmo expositor',
  select_category: 'Selecione uma categoria',
};

const chat: IVisitorChatMessages = {
  rooms_empty: 'Nenhuma conversa',
  room_empty: 'Nenhuma conversa seleccionada',
  new_messages: 'Novas mensages',
  message_deleted: 'Mensagem apagada',
  messages_empty: 'Nenhuma mensagem',
  conversation_started: 'Início da conversa em:',
  type_message: 'Escreva a sua mensagem',
  search: 'Procurar',
  is_online: 'está online',
  last_seen: 'última conexão',
  is_typing: 'está digitando...',
  cancel_select_message: 'Cancelar selecção',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  exhibitorSessions,
  profile,
  products,
  store,
  chat,
};
